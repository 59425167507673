import Reading from './Reading'
import { useSearchParams } from 'react-router-dom'
import Video from './Video'
import Presentation from './Presentation'
import Customize from './Customize'

const LessonType = () => {
  enum LessonTypes {
    READING = 'reading',
    VIDEO = 'video',
    PRESENTATION = 'presentation',
    CUSTOMIZE = 'customize',
  }

  const [searchParams] = useSearchParams()

  const lessonType = searchParams.get('lesson-type')

  return (
    <>
      {LessonTypes.READING === lessonType && <Reading />}
      {LessonTypes.VIDEO === lessonType && <Video />}
      {LessonTypes.PRESENTATION === lessonType && <Presentation />}
      {LessonTypes.CUSTOMIZE === lessonType && <Customize />}
    </>
  )
}

export default LessonType
