import { useEffect, useState } from 'react'
import EmptyTabComponent from '../../Components/EmptyTabComponent/EmptyTabComponent'
import classes from './CoursesModules.module.css'
import noModules from '../../Assets/Images/noModules.svg'
import CoursesPageContainer from '../CoursesPageContainer/CoursesPageContainer'
import useTutorCourses, {
  useTutorCourseOverallRating,
  useTutorCourseReviews,
} from '../../Hooks/useTutorCourses'
import Loader from '../../Components/Loader/Loader'
import useEnrolledStudents from '../../Hooks/useEnrolledStudents'
import { useSearchParams } from 'react-router-dom'
import { useCurriculum } from '../../Hooks/useCurricullum'
import { useStudentAssignments } from '../../Hooks/useStudents'
import useCourse from 'Services/Courses/Hooks/useCourse'

const CoursesModules = () => {
  // Riuter
  const [searchParams, setSearchParams] = useSearchParams()
  const activeCourseId = searchParams.get('activeCourse')

  // Requests
  // const { data, isLoading } = useTutorCourses()
  const { useGetTutorCourses, useGetCurriculumByCourseId } = useCourse()

  const { data: response, isLoading } = useGetTutorCourses()
  const { data: curriculumResponse, isLoading: isLoadingCurriculum } =
    useGetCurriculumByCourseId(activeCourseId)
  console.log('======>>>', curriculumResponse)
  // const { data: enrolledStudentsData, isLoading: enrolledStudentsIsLoading } =
  //   useEnrolledStudents()
  // const { isLoading: curricullumIsLoading, data: curricullum } = useCurriculum(
  //   activeCourseId as string
  // )
  // const { isLoading: reviewsIsLoading, data: reviewssData } =
  //   useTutorCourseReviews(activeCourseId as string)
  const { isLoading: overallRatingsIsLoading, data: overallRatingsData } =
    useTutorCourseOverallRating(activeCourseId as string)
  // const { isLoading: sttdentAssigbmentIsLoading, data: studentAssignmentData } =
  //   useStudentAssignments()

  // const enrolledStudents = enrolledStudentsData?.data
  // const curricullumData = curricullum?.data
  // const reviews = reviewssData?.data
  const overallRating = overallRatingsData?.data
  // const studentAssignment = studentAssignmentData?.data

  // Effects
  // useEffect(() => {
  //   if (data) {
  //     if (!activeCourseId) {
  //       setTutorCoursesState(
  //         data?.data?.map((data: any, i: number) => {
  //           if (i === 0) {
  //             return { ...data, isActive: true }
  //           } else {
  //             return { ...data, isActive: false }
  //           }
  //         })
  //       )

  //       setSearchParams((prevState) => {
  //         return { ...prevState, activeCourse: data?.data[0]?.course?.id }
  //       })
  //     } else {
  //       setTutorCoursesState(
  //         data?.data?.map((data: any, i: number) => {
  //           if (String(data?.course?.id) === activeCourseId) {
  //             return { ...data, isActive: true }
  //           } else {
  //             return { ...data, isActive: false }
  //           }
  //         })
  //       )
  //     }
  //   }

  //   // eslint-disable-next-line
  // }, [data, activeCourseId])

  const tooggleActiveCourse = (id: number) => {
    const newParams = new URLSearchParams(searchParams)
    newParams.set('activeCourse', String(id))

    setSearchParams(newParams)
  }

  if (
    isLoading ||
    // enrolledStudentsIsLoading ||
    // curricullumIsLoading ||
    // reviewsIsLoading ||
    overallRatingsIsLoading
    // sttdentAssigbmentIsLoading
  ) {
    return <Loader />
  }

  return (
    <div className={classes.container}>
      {response?.data?.length > 0 ? (
        <CoursesPageContainer
          courses={response?.data || []}
          enrolledStudents={null}
          tooggleActiveCourse={tooggleActiveCourse}
          curricullumData={curriculumResponse?.data}
          reviews={null}
          overallRating={overallRating}
          studentAssignment={null}
        />
      ) : (
        <EmptyTabComponent
          image={noModules}
          imageHeight={300}
          header="No assigned course "
          firstParagraph=" We're actively working on matching you with the perfect course based on your expertise."
          secondParagraph=" You'll receive an email notification once you’re assigned a course."
          route="/"
          buttontext="Create a lesson"
          showButton={false}
        />
      )}
    </div>
  )
}

export default CoursesModules
