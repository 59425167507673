import React from 'react'
import { useParams } from 'react-router-dom'
import CourseInfoLeftBar from '../../Containers/CreatingCourseModulePageContainer/CourseInfo/CourseInfoLeftBar'
import CurricullumHeader from '../../Containers/CurricullumHeader/CurricullumHeader'
import Layout from '../Layout/Layout'
import Loader from '../Loader/Loader'
import classes from './CourseCreationLayout.module.css'
import useCourse from 'Services/Courses/Hooks/useCourse'

type CourseCreationLayoutType = {
  children: React.ReactNode
  edit?: boolean
}

const CourseCreationLayout = ({ children, edit }: CourseCreationLayoutType) => {
  // Router
  const { courseId } = useParams()
  const { useGetCurriculumByCourseId, useGetCourseById } = useCourse()
  // Requests
  const { isLoading, data } = useGetCurriculumByCourseId(courseId || null)
  const { isLoading: courseIsLoading, data: courseData } = useGetCourseById(
    courseId || ''
  )

  const curriculum = data?.data
  const course = courseData?.data

  return (
    <Layout closeSideNav>
      {isLoading || courseIsLoading ? (
        <Loader />
      ) : (
        <div className={classes.container}>
          <CurricullumHeader />

          <div className={classes.body}>
            <CourseInfoLeftBar curriculum={curriculum} courseData={course} />
            {children}
          </div>
        </div>
      )}
    </Layout>
  )
}

export default CourseCreationLayout
