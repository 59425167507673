const endpoints = {
  GET_PROFILE: '/tutors/profile',
  UPDATE_CONTACT_INFO: '/tutors/profile/contact-info',
  CHANGE_PASSWORD: '/tutors/password/change',
  ONBOARDING: '/tutors/onboarding',
  CHANGE_EMAIL: '/tutors/update-email',
  GET_TUTOR_COURSES: '/tutors/my-courses',
}

export default endpoints
