import { useContext } from 'react'
import Button from '../../Components/Button/Button'
import GetStartedVideoContainer from '../GetStartedVideoContainer/GetStartedVideoContainer'
import classes from './CreateYourCurriculumContainer.module.css'
import { AppContext } from '../../Context/AppContext'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import CurricullumAccordion from './CurricullumAccordion'

type CreateYourCurriculumContainerType = {
  curricullum: any
  courseData: any
}

const CreateYourCurriculumContainer = ({
  curricullum,
  courseData,
}: CreateYourCurriculumContainerType) => {
  const { showGetStarted } = useContext(AppContext)

  //   Router
  const { courseId } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isEditing = searchParams.get('isEditing')

  const handleCreateOutlineClick = () => {
    if (!curricullum?.id) {
      navigate(`/courses/${courseId}/create-curricullum`)
    } else {
      navigate(
        `/courses/${courseId}/curriculum/${curricullum?.id}?curriculum-step=1`
      )
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.subHeader}>
        <div>
          <div>
            <svg
              width="26"
              height="18"
              viewBox="0 0 26 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 6.33333H25M1 11.6667H25M13 6.33333V17M3.66667 17H22.3333C23.8061 17 25 15.8061 25 14.3333V3.66667C25 2.19391 23.8061 1 22.3333 1H3.66667C2.19391 1 1 2.19391 1 3.66667V14.3333C1 15.8061 2.19391 17 3.66667 17Z"
                stroke="#664EFE"
                stroke-width="2"
              />
            </svg>
          </div>
          <div>
            <h3>
              {!curricullum?.course_modules
                ? 'Create your curriculum'
                : 'Course curriculum'}
            </h3>
            {isEditing && (
              <p>
                {courseData?.cohorts?.length > 0
                  ? courseData?.cohorts[0]?.name
                  : ''}
              </p>
            )}
          </div>
        </div>
        {isEditing && (
          <Button>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 12.5C15 14.1569 13.6569 15.5 12 15.5C10.3431 15.5 9 14.1569 9 12.5C9 10.8431 10.3431 9.5 12 9.5C13.6569 9.5 15 10.8431 15 12.5Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.45825 12.5C3.73253 8.44288 7.52281 5.5 12.0004 5.5C16.4781 5.5 20.2684 8.44291 21.5426 12.5C20.2684 16.5571 16.4781 19.5 12.0005 19.5C7.52281 19.5 3.73251 16.5571 2.45825 12.5Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Preview curriculum</span>
          </Button>
        )}
      </div>

      {!curricullum?.course_modules?.length ? (
        <div className={classes.subContainer}>
          <h4>Curriculum outline</h4>

          <>
            <p>
              Create an outline by structuring your content into weeks and
              lessons. Then, add a wide array of content to your lessons.
            </p>

            <Button onClick={handleCreateOutlineClick}>
              <span>Create outline</span>
              <svg
                width="20"
                height="10"
                viewBox="0 0 20 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 1L19 5M19 5L15 9M19 5L1 5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </>
        </div>
      ) : null}

      {!curricullum?.course_modules?.length ? (
        <div className={classes.getStartedVideo}>
          {showGetStarted.dashboard && (
            <GetStartedVideoContainer
              title="Quick Start Guide"
              paragraph="Welcome to the course creation journey! This Quick Start Guide will walk you through the essential steps to kickstart your course outline. Let' s make your curriculum shine!"
              videoHeight="280px"
            />
          )}
        </div>
      ) : null}

      {curricullum?.course_modules?.length ? (
        <CurricullumAccordion curriculum={curricullum} />
      ) : null}
    </div>
  )
}

export default CreateYourCurriculumContainer
