import { createContext, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { capitalize } from '../HelperFunctions/capitalize'
import { requestHandler2 } from '../HelperFunctions/requestHandler'
import { backend_url } from '../Utilities/global'
import {
  feedbackDataType,
  moduleDataType,
  uploadQuizData,
  uploadVideoData,
} from '../Utilities/types'
import { AppContext } from './AppContext'
import { requestType } from './AuthUserContext'

type CourseContextValuesTypes = {
  createCurricullim: (id: string, data: { title: string }) => void
  requestState: requestType
  createModule: (
    courseId: string,
    id: string,
    data: { title: string; objective: string[]; description: string }
  ) => void
  createWeek: (
    courseId: string,
    curriculumId: string,
    moduleId: string,
    data: { title: string }
  ) => void
  createLesson: (
    weekId: string,
    data: uploadVideoData | FormData | uploadQuizData,
    type: string
  ) => Promise<any>
  sendAssignmentFeedback: (
    id: string,
    data: any,
    status: 'rejected' | 'approved'
  ) => void
  updateModule: (moduleId: string, data: moduleDataType) => void
  deleteModule: (id: string) => void
  deleteWeek: (id: string) => void
  updateWeek: (weekId: string, data: { title: string }) => void
  deleteLesson: (id: string, lessonType: string) => void
  updateLesson: (
    lessonId: string,
    data: uploadVideoData | FormData | uploadQuizData,
    type: string
  ) => Promise<any>
}

type CourseContextProviderTypes = {
  children: React.ReactNode
}

export const CourseContext = createContext({} as CourseContextValuesTypes)

const CourseContextProvider = ({ children }: CourseContextProviderTypes) => {
  // COntext
  const { setNotifications } = useContext(AppContext)

  // States
  const [requestState, setRequestState] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  })

  // Router
  const navigate = useNavigate()

  // Requests
  const createCurricullim = (id: string, data: { title: string }) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/curriculum/${id}`,
      method: 'POST',
      data,
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      setNotificationsSuccess: true,
      successMessage: 'Curricullim created successfully',
      successFunction(res) {
        navigate(`/courses/${id}/curriculum/${res?.data?.id}`)
      },
      requestCleanup: true,
    })
  }

  const createModule = (
    courseId: string,
    id: string,
    data: { title: string; objective: string[]; description: string }
  ) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/course-modules/${id}`,
      method: 'POST',
      data,
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      setNotificationsSuccess: true,
      successMessage: 'Module created successfully',
      successFunction(res) {
        navigate(`/courses/${courseId}/${id}/${res?.data?.id}/create-week`)
      },
    })
  }

  const createWeek = (
    courseId: string,
    curriculumId: string,
    moduleId: string,
    data: { title: string }
  ) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/course-weeks/${moduleId}`,
      method: 'POST',
      data,
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      setNotificationsSuccess: true,
      successMessage: 'Course week created successfully',
      successFunction(res) {
        navigate(
          `/courses/${courseId}/${curriculumId}/${moduleId}/${res?.data?.id}/add-engaging-content`
        )
      },
      requestCleanup: true,
    })
  }

  const createLesson = (
    weekId: string,
    data: uploadVideoData | FormData | uploadQuizData,
    type: string
  ) => {
    return Promise.resolve(
      requestHandler2({
        url: `${backend_url}/api/ise/v1/${type}/${weekId}`,
        method: 'POST',
        data,
        state: requestState,
        setState: setRequestState,
        setNotifications,
        setNotificationsFailure: true,
        setNotificationsSuccess: true,
        successMessage: `${capitalize(type)} added successfully`,
        isMultipart: type === 'quiz' ? false : true,
        requestCleanup: true,
      })
    )
  }

  const updateLesson = (
    lessonId: string,
    data: uploadVideoData | FormData | uploadQuizData,
    type: string
  ) => {
    return requestHandler2({
      url: `${backend_url}/api/ise/v1/${type}/${lessonId}`,
      method: 'PATCH',
      data,
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      setNotificationsSuccess: true,
      successMessage: `${capitalize(type)} updated successfully`,
      isMultipart: type === 'quiz' ? false : true,
      requestCleanup: true,
    })
  }

  const sendAssignmentFeedback = (
    id: string,
    data: feedbackDataType,
    status: 'rejected' | 'approved'
  ) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/tutors/student_assignments/grade/${id}`,
      method: 'POST',
      data: { ...data, status, grade: data.grade || 'pending' },
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      setNotificationsSuccess: true,
      successMessage: `Feedback sent successfully`,
      requestCleanup: true,
    })
  }

  const deleteModule = (id: string) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/course-modules/${id}`,
      method: 'DELETE',
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      setNotificationsSuccess: true,
      successMessage: `Module deleted successfully`,
      requestCleanup: true,
      id: 'delete-module',
    })
  }

  const updateModule = (moduleId: string, data: moduleDataType) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/course-modules/${moduleId}`,
      method: 'PUT',
      data,
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      setNotificationsSuccess: true,
      successMessage: `Module updated successfully`,
      requestCleanup: true,
      id: 'update-module',
    })
  }

  const deleteWeek = (id: string) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/course-weeks/${id}`,
      method: 'DELETE',
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      setNotificationsSuccess: true,
      successMessage: `Lesson Week deleted successfully`,
      requestCleanup: true,
      id: 'delete-week',
    })
  }

  const updateWeek = (weekId: string, data: { title: string }) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/course-weeks/update/${weekId}`,
      method: 'PATCH',
      data,
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      setNotificationsSuccess: true,
      successMessage: `Week updated successfully`,
      requestCleanup: true,
      id: 'update-week',
    })
  }

  const deleteLesson = (id: string, lessonType: string) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/${lessonType}/${id}`,
      method: 'DELETE',
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      setNotificationsSuccess: true,
      successMessage: `Lesson deleted successfully`,
      requestCleanup: true,
      id: 'delete-lesson',
    })
  }

  return (
    <CourseContext.Provider
      value={{
        createCurricullim,
        requestState,
        createModule,
        createWeek,
        createLesson,
        sendAssignmentFeedback,
        updateModule,
        deleteModule,
        deleteWeek,
        updateWeek,
        deleteLesson,
        updateLesson,
      }}
    >
      {children}
    </CourseContext.Provider>
  )
}

export default CourseContextProvider
