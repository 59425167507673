import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import Button from 'Components/Button/Button'
import Input from 'Components/Input/Input'
import Loader from 'Components/Loader/Loader'

import classes from './CurriculumWeek.module.css'
import { inputChangeHandler } from 'HelperFunctions/inputChangeHandler'
import { addCourseWeek, updateCourseWeek } from 'Services/Courses'
import errors from 'Utilities/errors'
import { useApp } from 'Context/AppContext'
import { mutate } from 'swr'
import endpoints from 'Services/Courses/endpoints'
import useCourse from 'Services/Courses/Hooks/useCourse'

const getPreviousSearchParams = (prevSearchParamState: URLSearchParams) => {
  return Object.fromEntries(prevSearchParamState.entries())
}

const CurriculumWeek = () => {
  const isLoading = false
  const edit = false

  const [weekData, setWeekData] = useState({
    title: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const navigate = useNavigate()
  const { courseId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { setNotifications } = useApp()

  const weekId = searchParams.get('week-id')
  const moduleId = searchParams.get('module-id')

  const { useGetCourseWeekById } = useCourse()
  const { data: courseWeekResponse } = useGetCourseWeekById(weekId || null)

  useEffect(() => {
    if (courseWeekResponse?.data) {
      setWeekData({ title: courseWeekResponse?.data?.title || '' })
    }
  }, [courseWeekResponse?.data])

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)

      if (weekId) {
        // run an update
        await updateCourseWeek(weekId, weekData)

        mutate(endpoints.GET_CURRICULUM_BY_COURSE_ID(courseId || ''))

        setNotifications([
          {
            title: 'Successfully updated course week.',
            severity: 'success',
            id: Date.now(),
          },
        ])

        setSearchParams((prevState) => {
          const prev = getPreviousSearchParams(prevState)
          return { ...prev, 'curriculum-step': '3' }
        })

        return
      }

      // run a create
      const { data: response } = await addCourseWeek(moduleId || '', weekData)

      mutate(endpoints.GET_CURRICULUM_BY_COURSE_ID(courseId || ''))

      setNotifications([
        {
          title: 'Successfully created course week.',
          severity: 'success',
          id: Date.now(),
        },
      ])

      setSearchParams((prevState) => {
        const prev = getPreviousSearchParams(prevState)

        return {
          ...prev,
          'curriculum-step': '3',
          'week-id': response.id.toString(),
        }
      })
    } catch (err) {
      const errorMessage = errors.format(err)

      setNotifications([
        {
          title: errorMessage,
          severity: 'error',
          id: Date.now(),
        },
      ])
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleBack = () => {
    setSearchParams((prevState) => {
      const prev = getPreviousSearchParams(prevState)
      return { ...prev, 'curriculum-step': '1' }
    })
  }

  if (isLoading) {
    return (
      <div className={classes.addModules}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={classes.addModules}>
      <h3>Add new week to this module</h3>
      <p>
        Use weeks to give your modules a timeframe to guide course completion
      </p>
      <Input
        isRequired
        label="Week title"
        placeholder="E.g Week 1: Data Structures"
        value={weekData.title}
        name="title"
        onChange={(e) => inputChangeHandler(e, setWeekData)}
      />
      <div className={classes.addModulesBottom}>
        <div>
          <svg
            onClick={() => navigate('/support')}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 16H12V12H11M12 8H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
              stroke="#2E2E2E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <Button type="secondary" onClick={handleBack}>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 16.5L3 12.5M3 12.5L7 8.5M3 12.5L21 12.5"
              stroke="#664EFE"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>Back</span>
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={!weekData.title}
          loading={isSubmitting}
        >
          <span>Save and continue</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 8L21 12M21 12L17 16M21 12L3 12"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
      </div>
    </div>
  )
}

export default CurriculumWeek
