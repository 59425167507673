import classes from './CoursesCurriculumSetupContainer.module.css'
import CreateYourCurriculumContainer from '../CreateYourCurriculumContainer/CreateYourCurriculumContainer'
import CourseDetailsContainer from '../CourseDetailsContainer/CourseDetailsContainer'
import { useParams, useSearchParams } from 'react-router-dom'
import Loader from '../../Components/Loader/Loader'
import ErrorContainer from '../../Components/ErrorContainer/ErrorContainer'
import useCourse from 'Services/Courses/Hooks/useCourse'

const CoursesCurriculumSetupContainer = () => {
  // Router
  const { courseId } = useParams()
  const [searchParams] = useSearchParams()
  const isEditing = searchParams.get('isEditing')

  const { useGetCourseById, useGetCurriculumByCourseId } = useCourse()
  const {
    data: response,
    isLoading,
    mutate,
    error,
  } = useGetCourseById(courseId || '')

  const { data: curriculumResponse } = useGetCurriculumByCourseId(
    courseId || ''
  )

  const courseData = response?.data
  const curricullumData = curriculumResponse?.data

  if (isLoading) {
    return <Loader />
  }

  if (!error) {
    return (
      <div className={classes.container}>
        {!isEditing && (
          <div className={classes.header}>
            <h2>Course setup</h2>
          </div>
        )}

        {/* <CourseCurriculumFeedbackInformationBanner /> */}

        <div className={classes.subContainer}>
          <div style={isEditing ? { flex: '100%' } : { flex: '60%' }}>
            <CreateYourCurriculumContainer
              curricullum={curricullumData}
              courseData={courseData}
            />
          </div>

          <div style={isEditing ? { flex: '0%' } : { flex: '40%' }}>
            <CourseDetailsContainer courseData={courseData} />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <ErrorContainer
        fallback={() => {
          mutate()
        }}
      />
    )
  }
}

export default CoursesCurriculumSetupContainer
